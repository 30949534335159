const ID_TOKEN_KEY = "id_token";
const ID_Data_KEY = "User_data";
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};
export const saveUserData = data => {
  
  window.localStorage.setItem(ID_Data_KEY, JSON.stringify(data));
};
// export const getUserData = () => {
//   return window.localStorage.getItem(ID_Data_KEY);
// };

export default { getToken, saveToken, destroyToken ,saveUserData};



