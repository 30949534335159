import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import jwt from '@/core/services/auth/jwt/index.js'


// action types
// export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const EASYLOGIN = "EASYlogin";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const loginJWT = "loginJWT";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  token:null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          resolve(response);
          context.commit(SET_ERROR, response.data.error);
        });
    });
  },
  [EASYLOGIN](context, data) {
    return new Promise(resolve => {
          context.commit(SET_AUTH, data);
          resolve(data);
       
       
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
   
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
         
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        
        });
    });
  },
  // [VERIFY_AUTH](context) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.verify()
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //       });
  //   } else {
  //     context.commit(PURGE_AUTH);
  //   }
  // },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.data;
    state.errors = {};
    state.token = user.token;
    JwtService.saveToken(user.token);
   
    JwtService.saveUserData(user.data);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    window.localStorage.removeItem("User_data")
   
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
