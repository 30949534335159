<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
// @import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html

@import "assets/css/style.vue.rtl";
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
// window.location.origin.includes('almansah') ||
// m.almansah
if (window.location.href.includes("fehm") || window.location.href.includes("mcit")) {
  require("./assets/sass/_main.scss");
}
 if(!(window.location.href.includes("fehm") || window.location.href.includes("mcit"))) {
  require("./assets/sass/_mainElmamasa.scss");
}



export default {
  name: "E_meeting",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  watch: {
    $route(to, from) {
      if (!window.location.href.includes("login")) {
        sessionStorage.setItem("lastUrl", window.location.hash);
      }
    },
  },
  created() {
    var clear = window.localStorage.getItem("clear");
    if (clear) {
    } else {
      window.localStorage.removeItem("id_token");
      window.localStorage.removeItem("User_data");
      window.localStorage.setItem("clear", true);
      window.location.href = "/";
    }
  },
};
</script>
