import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)


// Vue 3rd party plugins

import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
// import "@/core/plugins/formvalidation";

import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax



import VueFileAgent from "vue-file-agent";
import  "vue-file-agent/dist/vue-file-agent.css";
Vue.use(Vuesax)


const moment = require('moment')
require('moment/locale/ar')
Vue.use(require('vue-moment'), {
  moment
})

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import { BCollapse } from 'bootstrap-vue'
Vue.component('b-collapse', BCollapse)

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
const options={
  inputOptions:{
    placeholder:"رقم الهاتف",
  },
  dropdownOptions:{
    showDialCodeInSelection:true
  },
  preferredCountries:['sa', 'ae', 'eg', 'qa', 'bh'],
  styleClasses:"tele_input"
}
Vue.use(VueTelInput,options)

Vue.use(VueFileAgent);
// countdown
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);
import "vue-wysiwyg/dist/vueWysiwyg.css";
import wysiwyg from "vue-wysiwyg";
const wysiwygOptions={
 maxHeight: "1500px"
}
Vue.use(wysiwyg, wysiwygOptions);

import TextTruncate from 'vue-text-truncate'
Vue.component('TextTruncate', TextTruncate)

// API service init
ApiService.init();

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import VueIframe from 'vue-iframes'

Vue.use(VueIframe)
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

var API_KEY = "YG0q9N8DQGe4HizqEbwXsw";

var API_SECRET = "MfPDGF3JybyfkoSidqheyQaDih387uEYoJ22";

const MeetingCfg = (meetingId, userName, userEmail,  pass = null) => ({

  apiKey: API_KEY,

  apiSecret: API_SECRET,

  meetingNumber: meetingId,

  userName: userName,

  passWord: pass,

  userEmail: userEmail,

  leaveUrl: 'https://www.google.com',

  role: 0

})

// what variable you hook into Vue's prototype will be available on ANY vue component

Vue.prototype.$MeetingCfg = MeetingCfg



import Pusher from "pusher-js"; // import Pusher
const token = window.localStorage.getItem("id_token");
// Pusher.logToConsole = true;
Vue.prototype.$pusher = new Pusher("sgdfdwf8656789876543", {
  httpHost: "socket.tstme.online",
  wsHost: "socket.tstme.online",
  httpPath: "/",
  forceTLS: true,
  useTLS: true,
  encrypted: true,
  enabledTransports: ["ws"],
  wsPort: 80,
  wssPort: 443,
  authEndpoint: process.env.VUE_APP_API_BASE_URL + '/broadcasting/auth',
  // authEndpoint: 'http://localhost/api/broadcasting/auth',
  authTransport:'ajax',
  auth: {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Accept':'application/json',
    }
  }
});




router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
next()
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(
    App)
}).$mount("#app");
