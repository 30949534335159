import Vue from "vue";
import Router from "vue-router";
import { mapGetters } from "vuex";
//   // mode: 'history',
Vue.use(Router);
 const userData = JSON.parse(localStorage.getItem('User_data'));
 
export default new Router({
 
  routes: [
    {
      path: "/",
      redirect: userData ? userData.roles.includes('admin') ? "/dashboard" : "/meetings_list" : '/login',
      component: () => import("@/view/layout/Layout"),
      children:
       [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/meetings_list",
          name: "قائمة الإجتماعات",
          component: () => import("@/view/pages/Meetings/MeetingsList.vue")
        },
        {
          path: "/new_meeting",
          name: "new_meeting",
          component: () => import("@/view/pages/Meetings/New_Meeting/NewMeeting.vue")
        },
        {
          path: "/add_meeting_details/:slug",
          name: "new_meeting_step2",
          component: () => import("@/view/pages/Meetings/New_Meeting/NewMeeting.vue")
        },
        {
          path: "/send_meeting_details/:slug_send",
          name: "new_meeting_step2",
          component: () => import("@/view/pages/Meetings/New_Meeting/NewMeeting.vue")
        },
        {
          path: "/view_meeting/:id",
          name: "مشاهدة الإجتماع",
          component: () => import("@/view/pages/Meetings/ShowMeeting.vue")
        }, {
          path: "/edit_meeting/:id",
          name: "تعديل الإجتماع",
          component: () => import("@/view/pages/Meetings/EditMeeting.vue")
        },
        {
          path: "/mt/:id",
          name: "الإجتماع الحالى",
          component: () => import("@/view/pages/Meetings/ActiveMeeting.vue")
        },
        {
          path: "/members_list",
          name: "قائمة الأعضاء",
          component: () => import("@/view/pages/Members.vue")
        },
        {
          path: "/groups",
          name: "المجموعات",
          component: () => import("@/view/pages/Groups/Groups.vue")
        },
        {
          path: "/add-group",
          name: "إضافة مجموعة",
          component: () => import("@/view/pages/Groups/Add-Group.vue")
        },
        {
          path: "/edit-group/:id",
          name: "تعديل مجموعة",
          component: () => import("@/view/pages/Groups/Add-Group.vue")
        },
        {
          path: "/finish-meeting",
          name: "تم الإنتهاء من الإجتماع",
          component: () => import("@/view/pages/Meetings/MeetingFinshed.vue")
        },
        {
          path: "/edit-profile",
          name: "تعديل الحساب",
          component: () => import("@/view/pages/Edit-profile.vue")
        },
        {
          path: "/meeting_finshed/:slug",
          name: "meeting_finshed",
          component: () => import("@/view/pages/Meetings/MeetingFinshed.vue")
        },
        


      ]
    
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/view/pages/auth/login_pages/Login")
    },
    {
      name: "login",
      path: "/login/:groupslug",
      component: () => import("@/view/pages/auth/login_pages/Login")
    },
    {
      name: "login_mt",
      path: "/login_mt/:meeting_id",
      component: () => import("@/view/pages/auth/login_pages/Login")
    },
    {
      name: "login_gust",
      path: "/login_gust/:meeting_id",
      component: () => import("@/view/pages/auth/login_pages/gust_Login")
    },
    
    {
      name: "EasyLoginGroup",
      path: "/m/:slug/:log_token/:user_group",
      component: () => import("@/view/pages/auth/login_pages/EasyLogin")
    },
    {
      name: "EasyLogin",
      path: "/m/:slug/:log_token",
      component: () => import("@/view/pages/auth/login_pages/EasyLogin")
    },
    {
      name: "validate Group Slug",
      path: "/join/:groupslug",
      component: () => import("@/view/pages/validate_Group_slug.vue")
    },
    {
      name: "New Register",
      path: "/Register/:groupslug",
      component: () => import("@/view/pages/auth/login_pages/registeration")
    },
    {
      name: "Mobile Validation",
      path: "/moblie-validtion/:moblie_number/:email",
      component: () => import("@/view/pages/auth/login_pages/registeration")
    },
    {
      name: "Email Validation",
      path: "/email_validtion/:email",
      component: () => import("@/view/pages/auth/login_pages/registeration")
    },
    {
      name: "Complete Data",
      path: "/Complete-Register/:activation_token/:email",
      component: () => import("@/view/pages/auth/login_pages/validate_and_completeData")
    },

    {
      path: "/zoom/:id/:pwd",
      name: "Zoom",
      component: () => import("@/components/ZoomFrame.vue")
    },


    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
