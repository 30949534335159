import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";

const state = {
  user_personal_info:{}
};
const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },
};

const actions = {
  [UPDATE_PERSONAL_INFO](context) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("/user_data")
        .then(({ data }) => {
          context.commit(SET_PERSONAL_INFO, data.data);
          resolve(data);

        })
        .catch(({ response }) => {
          
          resolve(response);
         
        });
    });
   
  },

};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },

};

export default {
  state,
  actions,
  mutations,
  getters
};
